import * as React from 'react';
import { useState } from 'react';

// @ts-ignore
import closeIcon from './close.svg';

const JobAlerts: React.FC = () => {
	const [popupOpened, setPopupOpened] = useState(false);

	React.useEffect(() => {
		document.addEventListener('keydown', (e) => {
			if (e.code == 'Escape') {
				setPopupOpened(false);
			}
		});
	}, []);

	React.useEffect(() => {
		if (popupOpened) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [popupOpened]);

	return (
		<section>
			<div
				className={
					'flex w-full flex-row flex-wrap items-center justify-center gap-3 bg-primary px-5 pt-8 text-center text-white'
				}
			>
				<h2 className={'mb-0 font-lato text-2xl font-bold text-white'}>
					Sign up for Job Alerts
				</h2>
				<button
					className={'main-button !px-6 !py-3'}
					onClick={() => {
						setPopupOpened(true);
					}}
				>
					JOIN
				</button>
			</div>
			<div
				className={[
					'fixed bottom-0 left-0 right-0 top-0 z-10 bg-[rgba(0,0,0,0.40)]',
					!popupOpened ? 'hidden' : ''
				].join(' ')}
			>
				<div
					className={
						'flex h-full w-full flex-row items-center justify-center'
					}
				>
					<div
						className={'container flex items-center justify-center'}
					>
						<div
							className={
								'rounded bg-white px-6 pb-4 shadow max-sm:px-3'
							}
						>
							<div className={'flex flex-row justify-between'}>
								<span
									className={
										' text-gp-green py-8 text-center font-lato text-2xl font-bold'
									}
								>
									JOIN THE TALENT COMMUNITY
								</span>
								<button
									onClick={() => {
										setPopupOpened(false);
									}}
								>
									<img
										width={26}
										className={'hover:opacity-70'}
										src={closeIcon}
										alt={'close popup window'}
									/>
								</button>
							</div>
							<iframe
								className={
									'max-sm:h-[45 0px] h-[70vh] max-h-full w-[95vw] max-w-[95vw]'
								}
								style={{ border: 'none', maxHeight: '100%' }}
								src={
									'https://recruiting.adp.com/srctcb/public/RTI.home?t=1849321'
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default JobAlerts;
